html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: rgba(255, 255, 255, 0.9) !important;

  /* background-color: #282c34; */

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

* + p {
  padding-top: 12px !important;
}

p + * {
  padding-top: 12px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Background {
  background: linear-gradient(20deg, #15343f,#2a0f2b, #232323);
  background-attachment: fixed;
  background-size: 100% 100%;
  background-position: center;
  overflow-x: hidden;
}

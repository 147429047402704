@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url(./fonts/roboto/Roboto-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/roboto/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url(./fonts/roboto/Roboto-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url(./fonts/roboto/Roboto-ThinItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url(./fonts/roboto/Roboto-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url(./fonts/roboto/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url(./fonts/roboto/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url(./fonts/roboto/Roboto-BlackItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/roboto-mono/RobotoMono-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/roboto-mono/RobotoMono-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 400;
    src: url(./fonts/roboto-mono/RobotoMono-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 700;
    src: url(./fonts/roboto-mono/RobotoMono-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/roboto-mono/RobotoMono-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 100;
    src: url(./fonts/roboto-mono/RobotoMono-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/roboto-mono/RobotoMono-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 100;
    src: url(./fonts/roboto-mono/RobotoMono-ThinItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 300;
    src: url(./fonts/roboto-mono/RobotoMono-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 500;
    src: url(./fonts/roboto-mono/RobotoMono-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/roboto-mono/RobotoMono-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 600;
    src: url(./fonts/roboto-mono/RobotoMono-SemiBoldItalic.ttf) format('truetype');
}